// Here you can add other styles
#overlay {
  position: absolute;
  display: none;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 3px;
  z-index: 50;
}

.overlay-visible {
  display: flex !important;
}

.react-select {
  position: relative;
  flex: 1 1 auto;
}

a {
  color: #B9794A;

  &:hover {
    color: #9A6239;
  }
}

.c-sidebar-brand {
  background: #784B2A !important;
}

.c-sidebar-nav {
  background: #141B1E !important;
}

.c-sidebar-nav-link {
  &:hover {
    background: #9A6239 !important;
  }
}

.btn-primary {
  color: #fff;
  background-color: #B9794A;
  border-color: #B9794A;

  &:hover {
    background-color: #9A6239!important;
    border-color: #9A6239!important;
  }
  &:focus, &:active {
    background-color: #9A6239!important;
    border-color: #9A6239!important;
    box-shadow: 0 0 0 0.2rem rgba(146, 101, 64, 0.5)!important;
  }
  &:disabled {
    background-color: rgba(146, 101, 64, 0.75)!important;
    border-color: rgba(146, 101, 64, 0.75)!important;
  }
}

.form-control {

  &:focus, &:active {
    box-shadow: 0 0 0 0.2rem rgba(146, 101, 64, 0.5)!important;

  }
}

.fc-button-primary {
  color: #fff!important;
  background-color: #B9794A!important;
  border-color: #B9794A!important;

  &:hover {
    background-color: #9A6239!important;
    border-color: #9A6239!important;
  }
  &:focus, &:active {
    background-color: #9A6239!important;
    border-color: #9A6239!important;
    box-shadow: 0 0 0 0.2rem rgba(146, 101, 64, 0.5)!important;
  }
  &:disabled {
    background-color: rgba(146, 101, 64, 0.75)!important;
    border-color: rgba(146, 101, 64, 0.75)!important;
  }
}